var generic = generic || {};
var site = site || {};

(function ($) {
  var contentBlockSignup = {
    setup: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.init(context);
    },
    init: function (context) {
      var $module = $('.js-content-block-signup-v1', context);
      var moduleLength = $module.length;
      var $emailForm = $();
      var $emailInput = $();
      var $emailSuccessMessage = $();
      var $emailErrorMessage = $();
      var $emailAlreadySignedUpMessage = $();
      var params = {};
      var errorMessage = '';
      var alreadySignedupMessage = '';
      var gdprCheckboxError = '';
      var $gdprCheckbox = $();
      var $thisModule = $();

      if (moduleLength > 0) {
        errorMessage = $module.data().errorText || '';
        alreadySignedupMessage = $module.data().signedUpText || '';
      }

      $module.each(function () {
        $thisModule = $(this);
        $emailForm = $thisModule.find('.js-content-block-signup__form');
        $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailForm);
        $gdprCheckbox = $('input[name="pc_email_promotions"]', $emailForm);
        gdprCheckboxError = $('input[name="error_required_gdpr_checkbox"]', $emailForm).val();
        $emailSuccessMessage = $thisModule.find('.js-content-block-signup__success');
        $emailErrorMessage = $thisModule.find('.js-content-block-signup__error');
        $emailAlreadySignedUpMessage = $thisModule.find('.js-content-block-signup__already-signed-up');

        $emailInput.on('keyup', function () {
          if ($(this).val() === '') {
            $(this).removeClass('non-empty');
          } else {
            $(this).addClass('non-empty');
          }
        });

        $emailForm.once().on('submit', function (e) {
          e.preventDefault();
          $thisModule.removeClass('content-block-signup--active-error');
          $emailSuccessMessage.add($emailErrorMessage).add($emailAlreadySignedUpMessage).addClass('hidden');
          $emailInput.removeClass('error');

          // String into array of form elements
          params.PC_EMAIL_PROMOTIONS = 0;
          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          if ($.cookie('csrftoken')) {
            params['_TOKEN'] = $.cookie('csrftoken');
          }
          if ($gdprCheckbox.attr('type') == 'checkbox' && !$gdprCheckbox.prop('checked')) {
            $emailErrorMessage.removeClass('hidden');
            $thisModule.addClass('content-block-signup--active-error');
            $emailErrorMessage.html(gdprCheckboxError);
          } else {
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [params],
              onSuccess: function (jsonRpcResponse) {
                var response = jsonRpcResponse.getData();
                var alreadySignedUp = response && response.userinfo ? response.userinfo.previously_opted_in_email_promotion : '';
                $thisModule.addClass('content-block-signup--active-success');
                if (alreadySignedUp) {
                  $emailAlreadySignedUpMessage.removeClass('hidden').html(alreadySignedupMessage);
                } else {
                  $emailSuccessMessage.removeClass('hidden');
                }
                $emailSuccessMessage.removeClass('hidden');
                $emailInput.val('').removeClass('non-empty');
                $(document).trigger('contentBlock.signupSuccess');
              },
              onFailure: function (jsonRpcResponse) {
                var errObjs = jsonRpcResponse.getMessages();
                var errors = '';
                var i;
                var myErr;
                
                $emailErrorMessage.removeClass('hidden');
                for (i = 0; i < errObjs.length; i++) {
                  myErr = errObjs[i];
                  if (myErr && myErr.key) {
                    if (myErr.key === 'required.pc_email_address.email_signup' || myErr.key === 'invalid.pc_email_address.email_signup') {
                      errors += myErr.text;
                      $emailInput.addClass('error').focus();
                    }
                  }
                }
                if (errorMessage) {
                  $thisModule.addClass('content-block-signup--active-error');
                  $emailErrorMessage.html(errorMessage);
                } else if (errors) {
                  $thisModule.addClass('content-block-signup--active-error');
                  $emailErrorMessage.html(errors);
                }
              }
            });
          }
        });
      });
    }

  };

  Drupal.behaviors.contentBlockSignupV1 = {
    attached: false,
    attach: function (context) {
      contentBlockSignup.setup(context);
    }
  };
})(jQuery);
